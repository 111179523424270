export const MENU_ITEMS = (isLoggedIn: boolean, isAdmin: boolean, history: any, logout: () => void) => {
  if (isLoggedIn) {
    const items = [
      {
        label: 'Sitede ne var?',
        identity: 'sitede-ne-var',
        onClick: () => history.push('/sitede-ne-var'),
      },
      {
        label: 'Pratik Bilgiler',
        identity: 'practical-info',
        onClick: () => history.push('/pratik-bilgiler/sik_sorulan_sorular'),
      },
      {
        label: 'Biz',
        identity: 'biz',
        onClick: () => history.push('/hakkimizda'),
      },

    //   {
    //     label: 'Kütüphane',
    //     identity: 'kutuphane',
    //     onClick: () => history.push('/kutuphane'),
    //   },
    //   {
    //     label: 'İş Birliği',
    //     identity: 'is-birligi',
    //     onClick: () => history.push('/is-birligi'),
    //   },
      {
        label: 'Bağış',
        identity: 'destek',
        onClick: () => window.open('https://fonzip.com/demdernek/sagirkadinhakdestek', '_blank'),
      },      {
        label: 'Profil',
        identity: 'profil',
        onClick: () => history.push('/profil'),
      },
      { label: 'Çıkış', identity: 'cikis', onClick: logout },
    ]

    if (isAdmin) {
      return [
        {
          label: 'Admin Paneli',
          identity: 'admin',
          onClick: () => history.push('/admin/users-list'),
        },
        ...items,
      ]
    }
    return items
  }

  return [
    {
      label: 'Sitede ne var?',
      identity: 'sitede-ne-var',
      onClick: () => history.push('/sitede-ne-var'),
    },
    {
      label: 'Pratik Bilgiler',
      identity: 'practical-info',
      onClick: () => history.push('/pratik-bilgiler/sik_sorulan_sorular'),
    },
    {
      label: 'Biz',
      identity: 'biz',
      onClick: () => history.push('/hakkimizda'),
    },
    // {
    //     label: 'Kütüphane',
    //     identity: 'kutuphane',
    //     onClick: () => history.push('/kutuphane'),
    // },
    // {
    //     label: 'İş Birliği',
    //     identity: 'is-birligi',
    //     onClick: () => history.push('/is-birligi'),
    // },
    {
      label: 'Bağış',
      identity: 'destek',
      onClick: () => window.open('https://fonzip.com/demdernek/sagirkadinhakdestek', '_blank'),
    },    {
      label: 'Kayıt ol',
      identity: 'kayit',
      onClick: () => history.push('/kayit-ol'),
    },
    {
      label: 'Giriş yap',
      identity: 'giris',
      onClick: () => history.push('/kayitli-kullanici-giris'),
    },
  ]
}
