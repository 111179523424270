import {
  AppBar,
  Button,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { FC, useState } from 'react'
import { Align } from '../../../common/components/Align'
import { ConfirmationDialog } from '../../../common/components/ConfirmationDialog'
import { Space } from '../../../common/components/Space'
import { VideoUrlInput } from '../../../common/components/VideoUrlInput'
import {
  LibraryEntry,
  LIBRARY_CATEGORIES,
} from '../../../types/domain'
import useStyles from './styles'
import { DropdownWithoutFormik } from '../../../common/components/DropdownWithoutFormik'

interface Props {
  setSelectedEntry: (e: LibraryEntry) => void
  selectedEntry: LibraryEntry
  updateLibraryEntryProperty: (
    field: keyof LibraryEntry,
    value: any
  ) => void
  saveLibraryEntry: () => void
  deleteLibraryEntry: () => void
  saveEnabled: boolean
}

export const LibraryEntryDetail: FC<Props> = ({
  selectedEntry,
  setSelectedEntry,
  updateLibraryEntryProperty,
  saveLibraryEntry,
  deleteLibraryEntry,
  saveEnabled,
}) => {
  const classes = useStyles()
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  return (
    <>
      <ConfirmationDialog
        onClose={() => setConfirmDeleteModalOpen(false)}
        open={confirmDeleteModalOpen}
        confirmBtnText='Sil'
        onConfirm={deleteLibraryEntry}
        content='Silmek istediginizden emin misiniz?'
      />
      <Paper className={classes.root}>
        <AppBar position='static' color='primary' className={classes.appBar}>
          <Toolbar>
            <Typography variant='h6' className={classes.title}>
              {selectedEntry.title}
            </Typography>
            <IconButton
              edge='end'
              color='inherit'
              onClick={() => setSelectedEntry(null)}
              aria-label='menu'>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <section className={classes.content}>
          <Space size={2} />
          <DropdownWithoutFormik
            label='Kategori'
            options={LIBRARY_CATEGORIES.map(c => ({
              label: c.label,
              value: c.id,
            }))}
            value={selectedEntry.category}
            setValue={v => updateLibraryEntryProperty('category', v)}
          />
          <Space size={4} />
          <TextField
            variant='outlined'
            value={selectedEntry.order}
            label='Sira'
            fullWidth
            type='number'
            size='small'
            onChange={({ target }) =>
              updateLibraryEntryProperty('order', parseInt(target.value))
            }
          />
          <Space size={4} />
          <TextField
            variant='outlined'
            value={selectedEntry.title}
            label='Baslik'
            fullWidth
            size='small'
            onChange={({ target }) =>
              updateLibraryEntryProperty('title', target.value)
            }
          />
          <Space size={4} />
          <TextField
            variant='outlined'
            value={selectedEntry.content}
            label='Icerik'
            multiline
            rows={2}
            fullWidth
            size='small'
            onChange={({ target }) =>
              updateLibraryEntryProperty('content', target.value)
            }
          />
          <Space size={2} />
          <TextField
            variant='outlined'
            value={selectedEntry.contentLink}
            label='Icerik linki'
            rows={1}
            fullWidth
            size='small'
            onChange={({ target }) =>
              updateLibraryEntryProperty('contentLink', target.value)
            }
          />
          <Space size={4} />
          <VideoUrlInput
            label='Resim Url'
            value={selectedEntry.thumbnailUrl}
            setValue={v => updateLibraryEntryProperty('thumbnailUrl', v)}
          />
          <Space size={4} />
          <Align justify='flex-end' fullWidth>
            <Button
              style={{ marginRight: 8, background: 'red', color: 'white' }}
              variant='contained'
              onClick={() => setConfirmDeleteModalOpen(true)}>
              Sil
            </Button>
            <Button
              variant='contained'
              color='primary'
              style={{ color: '#eee' }}
              onClick={saveLibraryEntry}
              disabled={!saveEnabled}>
              Kaydet
            </Button>
          </Align>
        </section>
      </Paper>
    </>
  )
}
