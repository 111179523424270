import { alpha, Checkbox, createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import ReactSelect, { components } from 'react-select'

import { FC } from 'react'
import { Space } from './Space'
import { Align } from './Align'

export interface DropdownOption {
  label: string
  value: string
  disabled?: boolean
  icon?: any
}

interface Props {
  id?: string
  options: DropdownOption[]
  value: any
  setValue: (v: any) => void
  label: string
  multi?: boolean
}

export const DropdownWithoutFormik: FC<Props> = ({ value, id, options, setValue, label, multi }) => {
  const classes = useStyles()
  if (multi) {
    return (
      <FormControl>
        <InputLabel shrink htmlFor='input' className={classes.label}>
          {label}
        </InputLabel>
        <Space size={1.5} />
        <ReactSelect
          placeholder='Lütfen cevap/larını seç'
          isMulti
          name='colors'
          options={options}
          hideSelectedOptions={false}
          isClearable={false}
          closeMenuOnSelect={false}
          components={{
            Option: ReactSelectOption,
          }}
          styles={{
            option: s => ({
              ...s,
              backgroundColor: '#fff',
              color: 'black',
              border: '1px solid #e1e1e1',
              cursor: 'pointer',
            }),
            menuList: s => ({ ...s, paddingTop: 0, paddingBottom: 0 }),
          }}
          value={value}
          onChange={setValue}
        />
      </FormControl>
    )
  }

  return (
    <FormControl>
      <InputLabel shrink htmlFor='input' className={classes.label}>
        {label}
      </InputLabel>
      <Space size={1.5} />
      <Select
        classes={{ select: classes.input }}
        // labelId='labelId'
        id={id}
        value={value}
        displayEmpty
        onChange={({ target }) => setValue(target.value)}>
        {[{ label: 'Lütfen cevabını seç', value: '', disabled: true }, ...options].map(o => (
          <MenuItem disabled={o.disabled} value={o.value} key={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(({ spacing, transitions, palette }) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: spacing(3),
      },
    },
    label: {
      fontWeight: 600,
      marginBottom: spacing(2),
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 12px',
      transition: transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: palette.primary.main,
      },
    },
  })
)

const ReactSelectOption: FC<any> = ({ ...rest }) => {
  return (
    <components.Option {...rest}>
      <Align align='center'>
        <Checkbox checked={rest.isSelected} color='primary' />
        <Align align='center'>
          {rest.data.icon}
          <div style={{ marginLeft: 6 }}>{rest.data.label}</div>
        </Align>
      </Align>
    </components.Option>
  )
}
