import { IoWarningOutline } from 'react-icons/io5'
import { RiQuestionnaireLine } from 'react-icons/ri'
import destekciler from '../assets/destekciler.png'
import destekle from '../assets/destekle.png'
import faydali_kaynaklar from '../assets/faydali_kaynaklar.png'
import haberler from '../assets/haberler.png'
import raporlar from '../assets/raporlar.png'

export interface User {
  id: string
  email: string
  isAdmin: string
}

export interface Course {
  id: string
  title: string
  description?: string
  videos: Video[]
}

export interface Video {
  id: string
  createdAt: string
  title: string
  order: number
  description: string
  videoUrl: string
  thumbnailUrl: string
  questions: Question[]
  isNew?: boolean
}

export type QuestionType = 'pre' | 'post'
export interface Question {
  id: string
  type: QuestionType
  order: number
  text: string
  videoUrl: string
  thumbnailUrl: string
  choices: Choice[]
  answer?: ChoiceLabel
  isNew?: boolean
}

export type ChoiceLabel = 'A' | 'B' | 'C' | 'D'
export interface Choice {
  id: string
  label: ChoiceLabel
  text: string
  videoUrl: string
  thumbnailUrl: string
  isNew?: boolean
}

export interface PracticalInfoEntry {
  id: string
  category: string
  order: number
  title: string
  content: string
  videoUrl: string
  thumbnailUrl: string
  isNew?: boolean
}

export interface CoopEntry {
  id: string
  category: string
  order: number
  title: string
  content: string
  contentLink: string
  videoUrl: string
  thumbnailUrl: string
  isNew?: boolean
}

export interface LibraryEntry {
  id: string
  category: string
  order: number
  title: string
  content: string
  contentLink: string
  videoUrl: string
  thumbnailUrl: string
  isNew?: boolean
}

export interface Progress {
  progress: VideoProgress[]
  isDone: boolean
}

export interface VideoProgress {
  id: string
  title: string
  videoUrl: string
  thumbnailUrl: string
  completedAt: string
  completedQuestions: CompletedQuestion[]
}

export interface CompletedQuestion {
  id: string
  type: QuestionType
  order: number
  text: string
  videoUrl: string
  thumbnailUrl: string
  completedAt: string
  answers: ChoiceLabel[]
  isCorrect: boolean
}

export interface CompletedAnswer {
  correctAnswer: string
  isCorrect: boolean
}

export const PRACTICAL_INFO_CATEGORIES = [
  {
    id: 'sik_sorulan_sorular',
    label: 'sık sorulan sorular',
    Icon: RiQuestionnaireLine,
  },
  {
    id: 'acil_durumlar',
    label: 'acil durumlar',
    Icon: IoWarningOutline,
  },
]

export const COOP_CATEGORIES = [
  {
    id: 'destekle',
    label: 'destekle',
    Icon: ({ className }: { className: string }) => <img className={className} src={destekle} alt="destekle" />,
  },
  {
    id: 'destekciler',
    label: 'destekçiler',
    Icon: ({ className }: { className: string }) => <img className={className} src={destekciler} alt="destekçiler" />,
  },
]

export const LIBRARY_CATEGORIES = [
  {
    id: 'haberler',
    label: 'haberler',
    Icon: ({ className }: { className: string }) => <img className={className} src={haberler} alt="haberler" />,
  },
  {
    id: 'raporlar',
    label: 'raporlar',
    Icon: ({ className }: { className: string }) => <img className={className} src={raporlar} alt="raporlar" />,
  },
  {
    id: 'faydali_kaynaklar',
    label: 'faydalı kaynaklar',
    Icon: ({ className }: { className: string }) => <img className={className} src={faydali_kaynaklar} alt="faydali kaynaklar" />,
  },
]

export interface TeamMember {
  name: string
  surname: string
  role: string
  src: string
}
