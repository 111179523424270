import {
  Box,
  Card,
  CardContent,
  Paper,
  Typography,
} from '@material-ui/core'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Align } from '../../common/components/Align'
import { Page } from '../../common/components/Page'
import useStyles from './styles'
import { useGetRequest } from '../../common/useGetRequest'
import { LIBRARY_CATEGORIES } from '../../types/domain'
import { AppLayout } from '../../common/components/Layout'
import { initializeGA } from '../../utils/googleAnalytics'

export const Library = () => {
  initializeGA('Kutuphane')
  const classes = useStyles()
  const { category } = useParams<{ category: string }>()
  const [selectedCategory, setSelectedCategory] = useState(
    category || LIBRARY_CATEGORIES[0].id
  )
  const { data: library, loading } = useGetRequest(
    '/library',
    {}
  )
  const history = useHistory()

  const onSelectedCategoryChange = (id: string) => {
    setSelectedCategory(id)
    history.push({ pathname: `/kutuphane/${id}` })
  }

  return (
    <Page>
      <AppLayout>
        <Box>
          <Typography align='center' variant='h2' gutterBottom>
            Kütüphane
          </Typography>
          <Typography align='center' gutterBottom>
            Bu bölümde, çalışmalarımız kapsamında hazırladığımız raporları, bizden güncel haberleri ve ilgini çekebileceğini düşündüğümüz bazı kaynakları bulabilirsin. 
          </Typography>
        </Box>
        <Box
          display='flex'
          mt={4}
          mb={4}
          justifyContent='space-between'
          className={classes.containerBox}>
          {LIBRARY_CATEGORIES.map(({ id, label, Icon }) => (
            <Box className={classes.boxWrapper} key={id}>
              <Card
                onClick={() => onSelectedCategoryChange(id)}
                className={
                  selectedCategory === id
                    ? classes.selectedCategoryCard
                    : classes.categoryCard
                }>
                <CardContent>
                  <Align vertical align='center'>
                    <Icon
                      className={
                        selectedCategory === id
                          ? classes.selectedCategoryCardIcon
                          : classes.categoryCardIcon
                      }
                    />
                    <Typography>{label}</Typography>
                  </Align>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>

        <Box mb={8}>
          {!loading &&
            (library[selectedCategory] || []).map(
              ({ thumbnailUrl, title, content, contentLink }) => (
                <Paper className={classes.infoCard} key={title}>
                  <Align fullWidth>
                    {thumbnailUrl && <img
                      alt={title}
                      src={thumbnailUrl}
                      className={classes.thumbnail}
                    />}
                    <Align vertical margin={[0, 1.5]}>
                      <Typography className={classes.infoCardTitle}>
                        {title}
                      </Typography>
                      <Typography>{content}</Typography>
                      {contentLink && (
                        <Typography>
                            Daha fazla bilgi için{' '}
                            <a target='_blank' rel='noreferrer' href={contentLink}>buraya</a>  
                            {' '}tıkla.
                        </Typography>
                      )}
                    </Align>
                  </Align>
                </Paper>
              )
            )}
        </Box>
      </AppLayout>
    </Page>
  )
}
