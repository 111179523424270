import { Box, Grid, Typography } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import { AiOutlineInstagram } from 'react-icons/ai'
import styled from 'styled-components'

import classNames from 'classnames'
import useStyles from './styles'
import { Align } from '../../Align'
import sabanciLogo from '../../../../assets/sabanci-logo.png'
import demLogo from '../../../../assets/dem-logo.png'
import { AppLayout } from '../../Layout'

const StyledSocialMediaLink = styled.a`
  display: flex;
  align-items: center;
`

export const Footer = () => {
  const classes = useStyles()

  const footerItems = () => (
    <Grid item xs={12} lg={7} className={classes.footerItemsContainer}>
      <Align align='center'>
        <MailOutlineIcon />
        <Typography variant='body2' className={classes.itemText}>
          info@demdernek.org
        </Typography>
      </Align>
      <Align align='center'>
        <LocationOnOutlinedIcon />
        <Typography
          variant='body2'
          className={classNames(classes.address, classes.itemText)}>
          Bereketzade Mah. Şişhane Sok. 3/3 <br />
          Beyoğlu / İstanbul
        </Typography>
      </Align>
      <Align align='center'>
        <StyledSocialMediaLink
          href='https://www.instagram.com/sagirkadinhak'
          target='_blank'
          rel='noreferrer'>
          <AiOutlineInstagram size={25} className={classes.socialMediaIcon} />
          <Typography variant='body2' className={classes.itemText}>
            instagram.com/sagirkadinhak
          </Typography>
        </StyledSocialMediaLink>
      </Align>
    </Grid>
  )

  const footerLogos = () => (
    <Grid item xs={12} lg={4} className={classes.footerLogosContainer}>
      <Box className={classes.logosWrapper}>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <img
            src={demLogo}
            className={classes.logoImg}
            alt='Dem Logo'
            style={{ paddingLeft: 0, paddingRight: 18 }}
          />
        </Box>
      </Box>
    </Grid>
  )

  const footerInfoSection = () => (
    <Box className={classes.infoSection}>
      <AppLayout>
        <Grid container justifyContent='space-between'>
          {footerItems()}
          {footerLogos()}
        </Grid>
      </AppLayout>
    </Box>
  )

  const footerLegalSection = () => (
    <Align vertical>
      <Align justify='center' className={classes.bottomBar}>
        <Typography variant='body2' className={classes.bottomBarText}>
          © 2022 Bir Dem Derneği projesidir.
        </Typography>
      </Align>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <img
          src={sabanciLogo}
          alt='Sabanci Logo'
          className={classes.bottomBarLogo}
        />
      </Box>
    </Align>
  )

  return (
    <Box component='footer'>
      {footerInfoSection()}
      {footerLegalSection()}
    </Box>
  )
}
