import { Button, Paper } from '@material-ui/core'
import { isEqual } from 'lodash'
import { FC, useState } from 'react'
import { makeRequest } from '../../../common/makeRequest'
import { useGetRequest } from '../../../common/useGetRequest'
import { AdminPage } from '../../../common/components/AdminPage'
import { Align } from '../../../common/components/Align'
import { Table } from '../../../common/components/Table'
import { LibraryEntry, LIBRARY_CATEGORIES } from '../../../types/domain'
import { LibraryTableRow } from './TableRow'
import { LibraryEntryDetail } from './LibraryEntryDetail'

const tableHeaders = [{ label: 'Kategori' }, { label: 'Sira' }, { label: 'Baslik' }]

export const LibraryAdmin: FC<{}> = () => {
  const [libraryEntries, setLibraryEntries] = useState([])
  const [selectedEntry, setSelectedEntry] = useState<LibraryEntry | null>(null)

  useGetRequest('/library', {}, d => {
    const pi = Object.keys(d)
      .reduce((arr, category) => [...arr, ...d[category]], [])
      .sort((a, b) => a.order - b.order)
    setLibraryEntries(pi)
  })

  const updateLibraryEntryProperty = (field: keyof LibraryEntry, value: any) => {
    setSelectedEntry(s => ({ ...s, [field]: value }))
  }

  const saveLibraryEntry = async () => {
    const { isNew, ...payload } = selectedEntry
    if (isNew) {
      makeRequest<LibraryEntry>({
        url: '/admin/library',
        method: 'POST',
        payload,
        onSuccess: created => {
          setLibraryEntries(ee => [...ee, created])
          setSelectedEntry(created)
        },
        onError: err => console.log(err), //TODO
      })
    } else {
      makeRequest({
        url: `/admin/library/${selectedEntry.id}`,
        method: 'PUT',
        payload,
        onSuccess: () => {
          setLibraryEntries(ee => ee.map(e => (e.id === selectedEntry.id ? selectedEntry : e)))
        },
        onError: err => console.log(err), //TODO
      })
    }
  }

  const deleteLibraryEntry = () => {
    makeRequest({
      url: `/admin/library/${selectedEntry.id}`,
      method: 'DELETE',
      onSuccess: () => {
        setLibraryEntries(ee => ee.filter(e => e.id !== selectedEntry.id))
        setSelectedEntry(null)
      },
      onError: err => console.log(err), //TODO
    })
  }

  const handleNewLibraryEntryClick = () => {
    setSelectedEntry({
      id: Date.now().toString(),
      category: LIBRARY_CATEGORIES[0]?.id,
      content: '',
      contentLink: '',
      order: 0,
      title: '',
      thumbnailUrl: '',
      videoUrl: '',
      isNew: true,
    })
  }

  const topBar = (
    <Align fullWidth justify='flex-end' margin={[-0.5, 0, 1, 0]}>
      <Button variant='contained' color='primary' onClick={handleNewLibraryEntryClick}>
        Kutuphane Girisi Ekle
      </Button>
    </Align>
  )

  return (
    <AdminPage
      topBar={topBar}
      mainContent={
        <Paper style={{ width: '100%' }}>
          <Table
            stickyHeader
            headers={tableHeaders}
            rows={libraryEntries.map(pi => (
              <LibraryTableRow
                key={pi.id}
                infoEntry={pi}
                selectedEntry={selectedEntry}
                setSelectedEntry={setSelectedEntry}
              />
            ))}
          />
        </Paper>
      }
      detailOpen={!!selectedEntry}
      detailComponent={
        <LibraryEntryDetail
          selectedEntry={selectedEntry}
          setSelectedEntry={setSelectedEntry}
          updateLibraryEntryProperty={updateLibraryEntryProperty}
          saveLibraryEntry={saveLibraryEntry}
          deleteLibraryEntry={deleteLibraryEntry}
          saveEnabled={
            selectedEntry &&
            !isEqual(
              selectedEntry,
              libraryEntries.find(e => e.id === selectedEntry.id)
            )
          }
        />
      }
    />
  )
}
