import { TableCell, TableRow } from '@material-ui/core'
import { FC } from 'react'
import { LibraryEntry } from '../../../types/domain'

interface Props {
  infoEntry: LibraryEntry
  selectedEntry: LibraryEntry | null
  setSelectedEntry: (e: LibraryEntry) => void
}

export const LibraryTableRow: FC<Props> = ({
  infoEntry,
  selectedEntry,
  setSelectedEntry,
}) => {
  const { id, order, title, category } = infoEntry
  return (
    <TableRow
      onClick={() => setSelectedEntry(infoEntry)}
      selected={selectedEntry?.id === id}
      hover
      style={{ cursor: 'pointer' }}>
      <TableCell>{category}</TableCell>
      <TableCell>{order}</TableCell>
      <TableCell>{title}</TableCell>
    </TableRow>
  )
}
